.card-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 1px 10px $cyan-shadow;
  border-radius: 20px;
  overflow: hidden;
  z-index: 1;
  position: relative;
  .card-image {
    width: 100%;
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0;
  }
  .card-author-float {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: $cyan-white;
    p {
      color: $white;
      font-size: 12px;
      margin-right: 5px;
      font-weight: 500;
    }
  }
  .card-content {
    padding: 10px 15px;
    width: 100%;
  }
  .card-title {
    height: 40px;
    width: 100%;
    overflow: hidden;
    padding-bottom: 5px;
    margin: 0;
    h1 {
      font-size: 14px;
      color: $text-black;
      font-weight: 500;
    }
  }
  .card-progress {
    width: 100%;
    padding: 0;
    padding: 5px 0;
    .progress {
      height: 5px;
    }
  }
  .card-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    h5 {
      font-size: 12px;
      font-weight: 500;
      color: $text-black;
      margin-bottom: 5px;
    }
    h6 {
      font-size: 10px;
      font-weight: 600;
      color: $text-grey;
    }
    &.lg-version {
      display: flex;
      @media (max-width: 500px) {
        display: none;
      }
    }
    &.sm-version {
      display: none;
      @media (max-width: 500px) {
        display: flex;
      }
    }
  }
  &.card-sm-row {
    margin-bottom: 20px;
    @media (max-width: 500px) {
      flex-direction: row;
      margin-bottom: 20px;
      .card-image {
        width: 110px;
        height: 97px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
        margin: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
      }
      .card-content {
        width: calc(100% - 120px);
        padding: 8px 0 8px 8px;
      }
      .card-title {
        height: 30px;
        margin: 0 0 5px;
        overflow: hidden;
        h1 {
          color: $text-black;
          font-weight: 500;
          font-size: 12px;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
      .card-bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-item {
          width: 39%;
          &:first-child {
            width: 59%;
          }
          h5 {
            font-size: 12px;
            font-weight: 400;
            color: $text-black;
            margin-bottom: 5px;
          }
          h6 {
            font-size: 10px;
            font-weight: 500;
            color: $text-grey;
            padding: 5px 0;
          }
        }
        &.lg-version {
          display: flex;
          @media (max-width: 500px) {
            display: none;
          }
        }
        &.sm-version {
          display: none;
          @media (max-width: 500px) {
            display: flex;
          }
        }
      }
      .card-author-float {
        display: none;
      }
      .card-author {
        padding: 5px;
        border-radius: 15px;
        background-color: $white;
        display: flex;
        box-shadow: 0 1px 4px $cyan-shadow;
        width: fit-content;
        img {
          width: 10px;
        }
        p {
          color: $text-grey;
          font-size: 8px;
          font-weight: 400;
          margin-right: 5px;
        }
      }
      .card-progress {
        display: none;
      }
    }
  }
}

.card-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 1px 10px $cyan-shadow;
  margin: 20px 0;
  &.row-done {
    margin: 10px 0;
    @media (max-width: 500px) {
      .card-bottom {
        .card-item {
          &:first-child {
            display: none;
          }
          &:nth-child(2) {
            width: 50%;
            display: block;
          }
          &:nth-child(3) {
            display: none;
          }
          &:nth-child(4) {
            width: 50%;
            display: block;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    padding: 0;
  }
  .card-image {
    width: 220px;
    height: 130px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    @media (max-width: 500px) {
      width: 110px;
      height: 87px;
    }
  }
  .card-content {
    width: calc(100% - 220px);
    padding-left: 15px;
    @media (max-width: 500px) {
      width: calc(100% - 120px);
      padding: 8px 0 8px 8px;
    }
  }
  .card-title {
    width: 100%;
    height: 40px;
    @media (max-width: 500px) {
      height: 30px;
      margin: 0 0 5px;
      overflow: hidden;
    }
    h1 {
      font-size: 14px;
      color: $text-black;
      font-weight: 500;
      @media (max-width: 500px) {
        font-size: 12px;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }
  .card-bottom {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 500px) {
      justify-content: space-between;
    }
    .card-item {
      width: 23.333%;
      &:first-child {
        width: 30%;
      }
      @media (max-width: 500px) {
        width: 39%;
        &:first-child {
          width: 59%;
        }
        &:nth-child(3),
        &:nth-child(4) {
          display: none;
        }
      }
      h5 {
        font-size: 14px;
        font-weight: 400;
        color: $text-black;
        margin-bottom: 5px;
        @media (max-width: 500px) {
          font-size: 12px;
        }
      }
      h6 {
        font-size: 12px;
        font-weight: 500;
        color: $text-grey;
        @media (max-width: 500px) {
          font-size: 10px;
          padding: 5px 0;
        }
      }
      .program-action {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        width: 100%;
        border-radius: 15px;
        background-color: $orange;
        color: $white;
        font-size: 14px;
        padding: 0 15px;
        font-weight: 500;
      }
    }
  }
  .card-author {
    padding: 5px 10px;
    border-radius: 15px;
    background-color: $white;
    display: flex;
    box-shadow: 0 1px 4px $cyan-shadow;
    width: fit-content;
    @media (max-width: 500px) {
      padding: 5px;
      img {
        width: 10px;
      }
    }
    p {
      color: $text-grey;
      font-size: 10px;
      margin-right: 5px;
      font-weight: 500;
      @media (max-width: 500px) {
        font-size: 8px;
        font-weight: 400;
      }
    }
  }
  &.no-padding {
    padding: 0;
    .card-image {
      width: 280px;
      height: 160px;
      @media (max-width: 500px) {
        width: 110px;
        height: 87px;
      }
    }
    .card-content {
      @media (max-width: 500px) {
        width: calc(100% - 120px);
        padding: 8px 0 8px 8px;
      }
    }
  }
}

.donatur-row {
  width: 100%;
  background-color: $white;
  padding: 15px 30px;
  box-shadow: 0 2px 13px $cyan-shadow;
  border-radius: 100px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  &:last-child {
    margin-bottom: 0;
  }
  .left-row {
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &.full-small {
      width: 100% !important;
      .donatur-content {
        width: calc(100% - 48px);
      }
    }
    @media (max-width: 500px) {
      width: calc(100% - 95px);
    }
  }
  .right-row {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding-right: 15px;
    @media (max-width: 500px) {
      width: 95px;
      padding-right: 0;
      .text-normal {
        font-size: 12px !important;
      }
    }
  }
  .message-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 0 65px;
    @media (max-width: 500px) {
      padding: 5px 0 0 48px;
    }
    > .btn {
      font-size: 14px;
      padding: 0 !important;
      color: $text-black;
      margin-right: 15px;
      @media (max-width: 500px) {
        font-size: 12px !important;
        margin-right: 0;
      }
      i {
        font-size: 18px;
        margin-right: 5px;
        color: $cyan;
        @media (max-width: 500px) {
          font-size: 16px;
        }
      }
      &:hover {
        border-color: transparent !important;
        color: $cyan;
      }
    }
    .text-message {
      width: calc(100% - 100px);
      @media (max-width: 500px) {
        width: calc(100% - 75px);
        .text-normal {
          font-size: 10px !important;
          display: flex;
          flex-direction: column;
          width: fit-content;
        }
        &.text-extra-small {
          font-size: 8px !important;
        }
      }
    }
  }
  .donatur-image {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    margin-right: 15px;
    border: 1px solid $cyan-shadow;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 500px) {
      height: 38px;
      width: 38px;
      border-radius: 50%;
      margin-right: 10px;
    }
    img {
      max-width: 100%;
    }
  }
  .donatur-content {
    width: calc(100% - 75px);
    padding: 5px 0 0;
    @media (max-width: 500px) {
      p {
        &.text-normal {
          font-size: 12px !important;
          display: flex;
          flex-direction: column;
          width: fit-content;
        }
        &.text-small {
          font-size: 10px !important;
        }
      }
    }
  }
}

.card-row-dashboard {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 500px) {
    height: 75px;
  }
  .card-image {
    width: 130px;
    height: 90px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    @media (max-width: 500px) {
      width: 85px;
      height: 70px;
    }
  }
  .card-content {
    width: calc(100% - 300px);
    height: 90px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 500px) {
      width: calc(100% - 180px);
      padding: 0 5px;
      height: 75px;
    }

    .card-content-top {
      h1 {
        font-size: 0.85rem;
        font-weight: 500;
        margin-bottom: 5px;
        @media (max-width: 500px) {
          font-size: 0.75rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      p {
        font-size: 0.75rem;
        font-weight: 500;
        color: $text-grey;
        @media (max-width: 500px) {
          font-size: 0.65rem;
        }
        i {
          font-size: 8px;
          margin: 0 5px 2px;
          color: $text-light;
        }
        span {
          color: $orange;
        }
      }
    }
    .card-date {
      p {
        font-size: 0.75rem;
        font-weight: 400;
        color: $text-grey;
        i {
          font-size: 8px;
          margin: 0 5px 2px;
          color: $text-light;
        }
      }
    }
  }
  .card-action {
    width: 170px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: 500px) {
      width: 90px;
      height: 75px;
    }
    .status-box {
      width: fit-content;
      padding: 5px 10px;
      font-size: 1rem;
      font-weight: 500;
      color: $orange;
      border: 2px solid $orange;
      border-radius: 50px;
      text-align: center;
      @media (max-width: 500px) {
        font-size: 0.65rem;
      }
    }
    a {
      font-size: 0.85rem;
      color: $orange;
      font-weight: 500;
      @media (max-width: 500px) {
        font-size: 0.65rem;
      }
      i {
        margin-left: 3px;
      }
    }
  }
}

.blog-card {
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
  .blog-image-wrapp {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 20px;
    img {
      width: 100%;
    }
  }
  .blog-date {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: fit-content;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    height: 38px;
    box-shadow: 0px 1px 20px -1px $cyan-shadow;
    color: $cyan;
    font-weight: 0.85rem;
    border-radius: 20px;
    font-weight: 500;
    @media (max-width: 500px) {
      height: 30px;
      font-size: 00.75rem;
    }
  }
  .blog-desc-wrapp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    margin-bottom: 20px;
    .blog-desc {
      margin-right: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      @media (max-width: 500px) {
        margin-right: 15px;
      }
      span {
        font-size: 0.85rem;
        color: $text-light;
        font-weight: 600;
        margin-left: 8px;
        @media (max-width: 500px) {
          font-size: 0.75rem;
        }
      }
    }
    .icon-blog-desc {
      color: $text-grey;
      height: 24px;
      @media (max-width: 500px) {
        height: 20px;
      }
    }
  }
  .blog-post-wrapp {
    width: 100%;
    position: relative;
    .blog-title {
      font-size: 1.25rem;
      color: $text-black;
      font-weight: 600;
      margin-bottom: 15px;
      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }
    .blog-post {
      width: 100%;
      position: relative;
    }
    .blog-action {
      width: 100%;
      display: flex;
      justify-content: end;
      a {
        width: fit-content;
        padding: 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        height: 38px;
        box-shadow: 0px 1px 20px -1px $cyan-shadow;
        color: $cyan;
        font-weight: 0.85rem;
        border-radius: 20px;
        font-weight: 500;
        @media (max-width: 500px) {
          height: 35px;
          font-size: 0.75rem;
        }
        &:hover,
        &:focus {
          box-shadow: 0px 1px 16px -1px $cyan-hover;
        }
      }
    }
  }
  .blog-share {
    width: 100%;
    margin-top: 30px;
    @media (max-width: 500px) {
      margin-bottom: 40px;
    }
    .list-inline {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .list-inline-item {
        margin-right: 10px;
        p {
          font-size: 1rem;
          color: $text-black;
          font-weight: 600;
          margin-right: 20px;
        }
      }
    }
    .blog-share-link {
      display: flex;
      height: 40px;
      width: 40px;
    }
  }
}
