html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: $poppins;
  font-size: 16px;
  font-weight: 400;
  color: $text-black;
  position: relative;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  color: $text-black;
  font-family: $poppins;
  font-weight: 600;
}
p {
  color: $text-black;
  margin-bottom: 0;
  font-family: $poppins;
  font-weight: 400;
  font-size: 0.875rem;
}
a {
  color: $text-black;
  margin-bottom: 0;
  font-family: $poppins;
  font-weight: 400;
  font-size: 0.875rem;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    color: $text-black;
    text-decoration: none;
  }
}
.container {
  width: 100%;
  max-width: 900px !important;
  &.mobile-base {
    max-width: 500px !important;
  }
}
.page-wrapper {
  width: 100%;
  background-color: $white;
  position: relative;
  min-height: calc(100vh - 90px);
  padding-top: 90px;
  &.auth-wrapper {
    .contact-float-wrapper {
      right: 0;
    }
  }
  &.h100 {
    height: 100vh;
    overflow: hidden;
  }
  @media (max-width: 500px) {
    padding-top: 75px;
    padding-bottom: 140px;
    &.auth-wrapper {
      padding-bottom: 40px;
    }
  }
}
.title-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    .link-more {
      display: none;
    }
  }
}
.profile_avatar {
  width: 100%;
  height: 100%;
  background-color: $cyan-white;
  color: $cyan;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-85 {
  width: 85% !important;
}
.w-65 {
  width: 65% !important;
}
.w-35 {
  width: 35% !important;
}
.w-15 {
  width: 15% !important;
}
.tab-content {
  width: 100%;
  display: none;
  &.show {
    display: block;
  }
}
.click-action-banner {
  width: 100%;
  padding: 40px 0 0;
  @media (max-width: 500px) {
    display: none;
  }
  .banner-menu {
    margin-top: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: $white;
    padding: 40px;
    height: 150px;
    border-radius: 75px;
    box-shadow: 0 1px 18px $cyan-shadow;
    @media (max-width: 500px) {
      height: 80px;
      border-radius: 40px;
      padding: 20px;
      margin-top: 60px;
    }
    img {
      position: absolute;
      left: 50px;
      top: -30%;
      @media (max-width: 500px) {
        height: 80px;
        left: 25px;
      }
    }
    .banner-text {
      padding-left: 200px;
      width: 65%;
      @media (max-width: 500px) {
        padding-left: 100px;
        width: 80%;
        .text-subtitle {
          font-size: 14px !important;
        }
        .text-normal {
          font-size: 8px !important;
        }
      }
    }
    .banner-action {
      display: flex;
      align-items: center;
      @media (max-width: 500px) {
        flex-direction: column;
      }
      .btn {
        margin: 0 10px;
        @media (max-width: 500px) {
          height: 28px;
          width: 55px !important;
          font-size: 8px;
          padding: 0;
        }
      }
    }
  }
}
.hr {
  border-top: 5px solid $cyan-white;
  &.light-color {
    border-color: $text-light;
  }
}
.hr-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background-color: $orange;
    margin: 0 8px;
    &.grey {
      width: 30px;
      background-color: $text-light;
    }
  }
}
.mobile-hidden {
  @media (max-width: 500px) {
    display: none !important;
  }
}
.mobile-flex {
  display: none;
  @media (max-width: 500px) {
    display: flex !important;
  }
}
.mobile-block {
  display: none;
  @media (max-width: 500px) {
    display: block !important;
  }
}
.title-float-section {
  position: absolute;
  width: 100%;
  top: 420px;
  left: 0;
  z-index: 1;
  @media (max-width: 500px) {
    top: 295px;
  }
  .title-float-text {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    padding: 0 30px;
    font-size: 1.25rem;
    font-weight: 600;
    color: $text-black;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 13px $cyan-shadow;
    @media (max-width: 500px) {
      height: 40px;
      border-radius: 20px;
      font-size: 1.125rem;
    }
  }
}
.main-content-section {
  padding: 40px 0;
  width: 100%;
  position: relative;
  @media (max-width: 500px) {
    padding: 20px 0;
    .text-subtitle {
      font-size: 0.85rem !important;
    }
  }
  .button-flex-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0;
  }
}
.program-closed-section {
  width: 100%;
  padding: 40px 0;
  text-align: center;
  @media (max-width: 500px) {
    padding: 20px 0;
    .btn {
      &.w-50 {
        width: 100% !important;
      }
    }
  }
  .text-content {
    width: 90%;
    margin: 0 auto 50px;
    @media (max-width: 500px) {
      width: 100%;
    }
    h1 {
      margin-bottom: 8px;
      @media (max-width: 500px) {
        font-size: 1rem !important;
      }
    }
    p {
      @media (max-width: 500px) {
        font-size: 0.75rem !important;
      }
    }
  }
}
