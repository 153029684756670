.text-black {
  color: $text-black !important;
}
.text-grey {
  color: $text-grey !important;
}
.text-light {
  color: $text-light !important;
}
.text-white {
  color: $white !important;
}
.text-orange {
  color: $orange !important;
}
.text-cyan {
  color: $cyan !important;
}
.text-solid {
  color: $text-solid !important;
}
.text-red {
  color: $red !important;
}
