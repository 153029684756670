.blog-search {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  .btn {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0px;
    right: 5px;
    font-size: 1rem;
    color: $cyan;
    @media (max-width: 500px) {
      font-size: 14px;
      width: 30px;
      right: 0;
    }
  }
}
.blog-list-wrapp {
  width: 100%;
  position: relative;
  @media (max-width: 500px) {
    margin-bottom: 40px;
  }
}
.blog-side-wrapp {
  width: 100%;
  position: relative;
  .taglist-wrapp {
    width: 100%;
    padding: 15px 10px;
    background-color: $cyan10;
    border-radius: 20px;
  }
  .list-inline {
    margin: 0;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 5px;
  }
  .taglist-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    padding: 0 10px;
    color: $cyan;
    font-size: 0.75rem;
    border: 1px solid $cyan;
    background-color: $white;
    border-radius: 20px;
    margin: 5px 0;
    font-weight: 500;
    @media (max-width: 500px) {
      font-size: 0.625rem;
      height: 35px;
    }
  }
  .image-rekening {
    width: 100%;
    margin-top: 20px;
  }
}
