.dasboard-table-wrapper {
  width: 100%;
  position: relative;
  padding: 20px 0 40px;
}

table {
  &.dataTable {
    &.program-mitra-table {
      width: 100%;
      margin-bottom: 20px;
      @media (max-width: 500px) {
        width: 100%;
        overflow-x: auto;
      }
      thead {
        @media (max-width: 500px) {
          width: 600px;
        }
        th {
          font-size: 0.85rem;
          font-weight: 600;
          color: $text-black;
          padding-left: 8px;
          border: unset;
          &:nth-child(1) {
            width: 25% !important;
          }
          &:nth-child(2) {
            width: 23% !important;
          }
          &:nth-child(3),
          &:nth-child(4) {
            width: 20% !important;
          }
          &:nth-child(5) {
            width: 12% !important;
          }
        }
      }
      tbody {
        @media (max-width: 500px) {
          width: 600px;
        }
        td {
          font-size: 0.75rem;
          font-weight: 400;
          color: $text-black;
          border-bottom: 3px solid $cyan-white;
          padding-top: 15px;
          padding-bottom: 15px;
          &:nth-child(1) {
            width: 27% !important;
          }
          &:nth-child(2) {
            width: 23% !important;
            text-align: end;
          }
          &:nth-child(3) {
            width: 20% !important;
          }
          &:nth-child(4) {
            width: 20% !important;
            text-align: end;
          }
          &:nth-child(5) {
            width: 10% !important;
          }
          a {
            font-size: 0.75rem;
            font-weight: 500;
            color: $cyan;
            text-decoration: underline;
          }
          p {
            font-size: 0.75rem;
            font-weight: 500;
            color: $text-black;
            margin-bottom: 3px;
            &.text-grey {
              font-size: 0.625rem;
            }
          }
        }
      }
    }
    &.no-footer {
      border: unset;
    }
  }
}

#programMitraTable_wrapper {
  margin-bottom: 15px;
  .dataTables_length {
    color: $text-black;
    font-size: 0.85rem;
    @media (max-width: 500px) {
      width: 100%;
      text-align: start;
    }
    label {
      font-size: 0.85rem;
      select {
        height: 38px;
        width: 80px;
        font-size: 0.85rem;
        background: transparent;
        background-image: url("../img/main/svg/icon_caret_select.svg");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 5px);
        background-position-y: 5px;
        appearance: none;
        border-color: $cyan-dark;
        border-radius: 19px;
        margin: 0 10px;
        padding: 4px 10px;
      }
    }
  }
  .dataTables_filter {
    @media (max-width: 500px) {
      width: 100%;
      text-align: start;
    }
    label {
      input {
        width: 250px;
        height: 38px;
        border-radius: 19px;
        padding: 0 10px;
        border: 2px solid $cyan;
        font-size: 0.85rem;
        font-weight: 500;
        &:hover,
        &:focus,
        &:visited {
          border-color: $cyan-dark;
          box-shadow: unset;
          outline: none;
        }
        &::placeholder {
          font-weight: 300;
          color: $text-light;
        }
      }
    }
  }
  .dataTables_info {
    color: $text-grey;
    font-size: 0.85rem;
    font-size: 500;
    @media (max-width: 500px) {
      width: 100%;
      text-align: start;
    }
  }
  .dataTables_paginate {
    font-size: 0.75rem;
    color: $text-grey;
    @media (max-width: 500px) {
      width: 100%;
      text-align: start;
    }
    a {
      height: 38px;
      width: 38px;
      border-radius: 19px;
      background-color: transparent;
      border-color: transparent;
      font-size: 500;
      color: $text-grey !important;
      &.disabled {
        background: $cyan50 !important;
        border-color: $cyan50 !important;
      }
      &:hover,
      &:focus,
      &:active,
      &.current {
        background: transparent !important;
        border-color: transparent !important;
        color: $cyan !important;
        text-decoration: underline !important;
        font-weight: 600 !important;
      }
      &.previous,
      &.next {
        background: $cyan !important;
        border-color: $cyan !important;
        color: $white !important;
        font-weight: 500 !important;
        &.disabled {
          background: $cyan50 !important;
          border-color: $cyan50 !important;
          &:hover,
          &:focus,
          &:active {
            background: $cyan50 !important;
            border-color: $cyan50 !important;
          }
        }
        &:hover,
        &:focus,
        &:active,
        &.current {
          background: $cyan-hover !important;
        }
      }
    }
    .ellipsis {
      font-size: 0.75rem;
      color: $text-grey;
      font-size: 500;
    }
  }
}

.table-card-dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  @media (max-width: 500px) {
    margin-top: 10px;
  }
  .table-row {
    width: 100%;
    margin: 10px 0;
    padding-bottom: 20px;
    border-bottom: 5px solid $cyan-white;
    &:last-child {
      border-bottom: unset;
      padding-bottom: 0;
    }
  }
}

.infaq-table {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  .infaq-table-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $text-light;
    margin-bottom: 10px;
    padding-bottom: 5px;
    @media (max-width: 500px) {
      justify-content: space-between;
    }
    .th {
      width: 25%;
      padding: 5px 15px;
      text-align: left;
      font-size: 1rem;
      font-weight: 600;
      @media (max-width: 500px) {
        width: 30%;
        font-size: 0.75rem;
        padding: 0 5px;
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
  .infaq-table-body {
    width: 100%;
    .tr {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      margin-bottom: 5px;
      @media (max-width: 500px) {
        justify-content: space-between;
      }
      .td {
        width: 25%;
        padding: 5px 10px;
        font-size: 0.85rem;
        text-align: left;
        @media (max-width: 500px) {
          width: 30%;
          font-size: 0.625rem;
          padding: 0 5px;
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}
