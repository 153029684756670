.modal {
  &.mobile-menu-dialog {
    top: 80px;
    .modal-content {
      border-radius: 40px;
    }
  }
  &.modal-category {
    .modal-dialog {
      max-width: 900px !important;
      position: fixed;
      bottom: 68px;
      width: 100%;
      margin: 0 auto;
    }
    .modal-content {
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    @media (min-width: 576px) {
      .modal-dialog {
        max-width: 900px !important;
        position: fixed;
        left: 50%;
        bottom: 0;
        width: 100%;
        transform: translateX(-50%);
        margin: 0;
      }
    }
  }
}

.mobile-menu-wrapper {
  width: 100%;
  .mobile-menu-top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    > a {
      width: 30%;
      margin: 0 8px;
    }
  }
  .mobile-menu-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > a {
      width: 100%;
      display: block;
      padding: 8px 0;
      font-size: 16px;
      font-weight: 500;
      span {
        display: block;
        border-bottom: 1px solid $cyan;
        width: fit-content;
      }
    }
  }
  .mobile-menu-bottom {
    width: 100%;
    margin-top: 20px;
  }
}

.modal-category-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  @media (max-width: 500px) {
    padding: 10px;
    h5 {
      font-size: 16px !important;
    }
    > button {
      font-size: 20px !important;
    }
  }
}
