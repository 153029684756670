.dashboard-profile-section {
  padding: 40px 0;
  width: 100%;
  @media (max-width: 500px) {
    padding: 0 0 20px;
  }
}
.dashboard-profil-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  border-radius: 70px;
  @media (max-width: 500px) {
    width: calc(100% + 30px);
    border-radius: 0;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
}
.dashboard-profil-left {
  width: 100%;
  padding: 20px;
  background-color: $cyan25;
  display: flex;
  z-index: 1;
  @media (max-width: 500px) {
    width: 100%;
  }
  .profil-image {
    width: 90px;
    height: 90px;
    border-radius: 45px;
    overflow: hidden;
    border: 1px solid $text-light;
    img {
      max-width: 100%;
    }
  }
  .profil-text {
    padding: 5px 20px;
    .profil-name {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .profil-account {
      padding-left: 20px;
      position: relative;
      &::before {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        content: "";
        background-color: $white;
        position: absolute;
        top: 5px;
        left: 3px;
        z-index: 3;
      }
      p {
        font-size: 0.75rem;
        font-weight: 600;
      }
    }
  }
}
.dashboard-profil-center {
  position: absolute;
  width: 50%;
  background-color: $cyan50;
  padding: 20px;
  z-index: 3;
  left: 40%;
  border-radius: 75px;
  @media (max-width: 500px) {
    width: 50%;
    position: relative;
    left: 0;
    border-radius: 0;
    background-color: $white;
    display: flex;
    justify-content: center;
    padding: 20px 15px;
  }
}
.dashboard-profil-right {
  position: absolute;
  width: 30%;
  background-color: $cyan;
  padding: 20px;
  z-index: 5;
  right: 0;
  border-radius: 75px;
  @media (max-width: 500px) {
    width: 50%;
    position: relative;
    left: 0;
    border-radius: 0;
    background-color: $white;
    display: flex;
    justify-content: center;
    padding: 20px 15px;
  }
}
.profil-box {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .icon-box {
    height: 35px;
    margin-bottom: 10px;
  }
}

.dashboard-content-section {
  padding: 40px 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 500px) {
    padding: 20px 0;
  }
}
.dashboard-menu-wrapper {
  width: 200px;
  display: block;
  @media (max-width: 500px) {
    display: none;
  }
  .menu-dashboard {
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
    align-items: center;
    .menu-icon {
      height: 24px;
      width: 24px;
      color: $text-grey;
      margin-right: 10px;
    }
    span {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1;
      color: $text-grey;
    }
    &:hover,
    &:focus,
    &.active {
      span,
      .menu-icon {
        color: $cyan-dark !important;
      }
    }
  }
}
.dashboard-content-wrapper {
  width: calc(100% - 200px);
  padding: 0 0 0 20px;
  @media (max-width: 500px) {
    width: 100%;
    padding: 0;
  }
}
.dashboard-content-head {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  .banner-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 500px) {
      background-image: url("../img/main/banner_donasi_rutin.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
      padding: 40px 20px;
      margin: -20px -15px 0;
      width: calc(100% + 30px);
      flex-direction: column;
      text-align: center;
      .text-title {
        font-size: 1rem !important;
        color: $white;
        margin-bottom: 10px;
      }
      .text-normal {
        font-size: 0.85rem !important;
        color: $white;
      }
      &.banner-zakat {
        flex-direction: column-reverse;
        position: relative;
        padding-bottom: 30px;
      }
    }
    img {
      height: 120px;
      margin-right: 15px;
      @media (max-width: 500px) {
        height: 80px;
        margin: 0 0 20px;
      }
    }
    p {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  &.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 500px) {
    .text-title {
      font-size: 1rem !important;
    }
    .text-normal {
      font-size: 0.75rem !important;
    }
  }
  .button-zakat-wrap {
    position: absolute;
    bottom: -28px;
  }
}
.dashboard-form {
  width: 100%;
}
.dashboard-upper-section {
  width: 100%;
  padding: 40px 0;
  .back-page {
    font-size: 1.125rem;
    font-weight: 300;
    color: $text-black;
    i {
      margin-right: 5px;
      color: $cyan;
    }
  }
}

.dashboard-card-group {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  .card-widget {
    width: calc(30% - 30px);
    padding: 20px 15px;
    border-radius: 50px;
    background-color: $white;
    color: $text-black;
    box-shadow: 0 1px 10px $cyan-shadow;
    margin: 0 15px;
    text-align: center;
    @media (max-width: 500px) {
      width: calc(33.33% - 16px);
      margin: 0 8px;
      .text-subtitle {
        font-size: 1rem !important;
      }
      .text-normal {
        font-size: 0.75rem !important;
      }
    }
  }
}
.dashboard-program-overview {
  width: 100%;
  background-color: $white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 1px 10px $cyan-shadow;
  display: flex;
  flex-direction: column;
  .row-overview {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    @media (max-width: 500px) {
      .text-subtitle {
        font-size: 0.85rem !important;
      }
      .text-normal {
        font-size: 0.65rem !important;
      }
      .text-title-two {
        font-size: 1rem !important;
      }
    }
    .row-left {
      max-width: 80%;
      @media (max-width: 500px) {
        max-width: 65%;
      }
    }
    .row-right {
      text-align: end;
      width: 250px;
      @media (max-width: 500px) {
        max-width: 35%;
      }
    }
  }
}
.box-setting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 20px;
  border-bottom: 5px solid $cyan-white;
  @media (max-width: 500px) {
    flex-direction: column;
  }
  .setting-left {
    width: 35%;
    @media (max-width: 500px) {
      width: 100%;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 10px;
      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }
    p {
      font-size: 0.85rem;
      @media (max-width: 500px) {
        font-size: 0.75rem;
      }
    }
  }
  .setting-right {
    width: 65%;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
}

.regular-donation-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.regular-donation-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: $white;
  box-shadow: 0 1px 10px $cyan-shadow;
  border-radius: 50px;
  padding: 20px 30px;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    padding: 20px;
    align-items: center;
  }
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 40px;
    @media (max-width: 500px) {
      width: 35px;
    }
  }
  .text-content {
    width: calc(100% - 200px);
    @media (max-width: 500px) {
      width: calc(100% - 125px);
      .text-semi-normal {
        font-size: 0.85rem !important;
      }
      .text-normal {
        font-size: 0.75rem !important;
      }
    }
  }
  .btn {
    width: 120px;
    @media (max-width: 500px) {
      width: 70px;
      font-size: 0.75rem;
      padding: 0;
      height: 38px;
    }
  }
}

.dashboard-content-bottom {
  padding: 40px 0;
  width: 100%;
  @media (max-width: 500px) {
    padding: 20px 0;
    .regular-donation-form {
      padding: 0;
      margin-bottom: 40px !important;
    }
    .title-wrapper {
      flex-direction: column-reverse;
      .w-50 {
        width: 100% !important;
        margin-bottom: 10px;
        .text-subtitle {
          font-size: 0.85rem !important;
        }
      }
    }
  }
}
