.program-upper-wrapper {
  width: 100%;
  padding: 40px 0;
  @media (max-width: 500px) {
    padding: 20px 0;
  }
  .banner-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 10px 15px;
    width: 80%;
    margin: 0 auto;
    @media (max-width: 500px) {
      padding: 5px 0px;
    }
    img {
      height: 120px;
      @media (max-width: 500px) {
        height: 80px;
        left: 25px;
      }
    }
    .banner-text {
      padding-left: 20px;
      width: calc(100% - 140px);
      @media (max-width: 500px) {
        width: calc(100% - 80px);
        .text-subtitle {
          font-size: 14px !important;
        }
        .text-normal {
          display: none;
        }
      }
    }
  }
}
.program-content-wrapper {
  width: 100%;
  padding: 40px 0;
  @media (max-width: 500px) {
    padding: 20px 0;
  }
  .title-wrapper {
    &.filter {
      margin-bottom: 30px;
      @media (max-width: 500px) {
        flex-direction: column-reverse;
        .w-65,
        .w-35 {
          width: 100% !important;
        }
        .w-35 {
          margin-bottom: 15px;
        }
        .text-title {
          font-size: 18px !important;
        }
      }
    }
  }
  .link-more {
    @media (max-width: 500px) {
      width: 100% !important;
    }
  }
}

.detail-program-section {
  width: 100%;
  padding: 40px 0;
  position: relative;
  background-color: $white;
  @media (max-width: 500px) {
    padding: 20px 0;
  }
}
.detail-program-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  padding-bottom: 40px;
  @media (max-width: 500px) {
    flex-direction: column;
    padding-bottom: 20px;
  }
  .image-wrapper {
    width: 410px;
    height: 250px;
    border-radius: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (max-width: 500px) {
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-top: -20px;
      border-radius: 0;
    }
  }
  .content-wrapper {
    width: calc(100% - 410px);
    padding-left: 20px;
    @media (max-width: 500px) {
      width: 100%;
      padding: 10px 0 0;
      h1 {
        font-size: 16px !important;
        margin-bottom: 10px !important;
      }
      p {
        font-size: 12px !important;
        margin-bottom: 0px !important;
        span {
          font-size: 14px !important;
        }
      }
      .row {
        margin: 20px -15px;
      }
    }
    .author {
      padding: 5px;
      border-radius: 15px;
      background-color: $white;
      display: flex;
      box-shadow: 0 1px 4px $cyan-shadow;
      width: fit-content;
      img {
        width: 12px;
        @media (max-width: 500px) {
          width: 10px;
          height: 10px;
        }
      }
      p {
        color: $text-grey;
        font-size: 10px;
        font-weight: 400;
        margin-right: 5px;
        @media (max-width: 500px) {
          font-size: 8px !important;
          margin-bottom: 0 !important;
        }
      }
    }
    .progress {
      height: 5px;
    }
  }
}
.detail-program-tab-header {
  width: 100%;
  position: relative;
  &.fixed {
    position: fixed;
    top: 90px;
    left: 0;
    background-color: $white;
    z-index: 9;
    @media (max-width: 500px) {
      top: 75px;
    }
    .tab-header-content {
      padding: 20px 15px;
    }
  }
  .tab-header-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    max-width: 900px !important;
    margin: 0 auto;
    padding: 20px 0;
    .tabs-button {
      width: fit-content;
      padding: 0 25px !important;
      @media (max-width: 500px) {
        padding: 0 10px !important;
      }
    }
    > .button-orange {
      padding: 0 50px !important;
      @media (max-width: 500px) {
        display: none;
      }
    }
    .social-wrapper {
      width: 140px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 500px) {
        display: none;
      }
      .social-link {
        height: 40px;
        width: 40px;
        margin-left: 5px;
        display: flex;
        font-size: 35px;
        color: $text-black;
        background-color: transparent;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.detail-program-tab-panel {
  width: 100%;
  padding: 40px 0 0;
  @media (max-width: 500px) {
    padding-top: 20px;
    .text-subtitle {
      font-size: 1rem !important;
    }
    .text-normal {
      font-size: 0.75rem !important;
    }
    .mb-4 {
      margin-bottom: 10px !important;
    }
  }
}

.activity-timeline {
  position: relative;
  padding: 0 0 0 20px;
  margin: 0 0 15px;
  list-style: none;
  .timeline-wrapper {
    position: relative;
    padding: 5px 15px 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    margin-left: 4px;
    background-color: $white;
    &::after {
      height: 100%;
      width: 2px;
      background-color: $text-light;
      position: absolute;
      left: -17px;
      top: 19px;
      content: "";
      z-index: 1;
    }
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      background-color: $cyan;
      border: 5px solid $white;
      border-radius: 100%;
      box-shadow: 0 2px 10px $cyan-shadow;
      left: -26px;
      top: 0px;
      z-index: 3;
    }
    &:last-child {
      &::after {
        height: 0;
      }
    }
    &.bg-cyan {
      background-color: $cyan-white;
      padding: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .timeline-header {
    width: 100%;
    margin-bottom: 20px;
    .timeline-date {
      font-size: 0.85rem;
      color: $text-light;
      font-weight: 400;
      @media (max-width: 500px) {
        font-size: 0.625rem;
      }
    }
    .timeline-title {
      font-size: 1.125rem;
      font-weight: 500;
      color: $text-black;
      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }
  }
}

.detail-program-activity-upper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
  .activity-upper-content {
    width: 60%;
    @media (max-width: 500px) {
      width: calc(100% - 130px);
      .text-title-two {
        font-size: 1rem !important;
      }
      .text-semi-normal {
        font-size: 0.85rem !important;
      }
      .text-normal {
        display: none;
      }
    }
  }
  .activity-upper-image {
    width: 35%;
    @media (max-width: 500px) {
      width: 120px;
    }
    img {
      width: 100%;
    }
  }
  .activity-upper-text {
    width: 100%;
    display: none;
    @media (max-width: 500px) {
      display: block;
      margin-top: 10px;
      .text-normal {
        font-size: 0.75rem;
      }
    }
  }
}

.detail-program-list {
  width: 100%;
  padding: 40px 0;
  @media (max-width: 500px) {
    padding: 20px 0;
  }
}
.detail-program-donatur-filter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    justify-content: space-between;
    align-items: flex-end;
  }
  .form-filter {
    max-width: 250px;
    margin-left: 20px;
    @media (max-width: 500px) {
      display: none;
    }
  }
  .dropdown {
    display: none;
    @media (max-width: 500px) {
      display: block;
    }
  }
}
