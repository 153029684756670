a.btn,
.btn {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  padding: 0 1.5rem;
  border-radius: 20px;
  font-size: 1.125rem;
  &.button-transparent {
    color: $cyan;
    background-color: transparent;
    border-color: transparent;
    &:hover,
    &:active,
    &:focus {
      border-color: $cyan;
      box-shadow: unset;
      outline: unset;
    }
  }
  &.button-cyan {
    color: $white;
    background-color: $cyan;
    &:hover,
    &:active,
    &:focus {
      border-color: $cyan-hover;
      background-color: $cyan-hover;
    }
  }
  &.button-cyan-border {
    color: $cyan;
    background-color: $white;
    border-color: $cyan;
    &:hover,
    &:active,
    &:focus {
      color: $cyan-hover;
      border-color: $cyan-hover;
      background-color: $white;
    }
  }
  &.button-slick {
    background-color: $white;
    background: $white;
    color: $cyan;
    height: 40px;
    width: 40px;
    padding: 0;
    opacity: 1;
    box-shadow: 0 1px 4px $shadow !important;
    z-index: 1;
  }
  &.button-mobile-menu {
    height: 40px;
    width: 40px;
    padding: 0;
    img {
      height: 40px;
      @media (max-width: 500px) {
        height: auto;
        width: 40px;
      }
    }
    &:hover,
    &:active,
    &:focus {
      box-shadow: unset;
      outline: unset;
    }
  }
  &.button-close-footer {
    position: absolute;
    top: 10px;
    right: 0;
    height: 40px;
    width: 25px;
    background-color: transparent;
    color: $text-grey;
    font-size: 24px;
    display: none;
    &:hover,
    &:active,
    &:focus {
      box-shadow: unset;
      outline: unset;
    }
    @media (max-width: 500px) {
      display: flex;
    }
  }
  &.button-to-top {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 1px 4px $text-light;
    font-size: 28px;
    padding: 0;
    color: $cyan;
    @media (max-width: 500px) {
      width: 40px;
      height: 40px;
      font-size: 18px;
    }
  }
  &.button-whatsapp-float {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 1px 4px $text-light;
    font-size: 48px;
    padding: 0;
    color: $whatsapp;
    @media (max-width: 500px) {
      width: 50px;
      height: 50px;
      font-size: 24px;
    }
  }
  &.text-link {
    font-weight: 500 !important;
    font-size: 1rem;
    color: $cyan !important;
    background-color: transparent;
    border-color: transparent;
    &:hover {
      color: $cyan-hover !important;
      background-color: transparent;
      border-color: transparent;
    }
  }
  &.button-sorting {
    height: 40px;
    width: 40px;
    background-color: transparent;
    border-color: transparent;
    padding: 0;
    @media (max-width: 500px) {
      height: 38px;
      width: 38px;
    }
    img {
      width: 35px;
      @media (max-width: 500px) {
        width: 30px;
      }
    }
    &:hover,
    &:active,
    &:focus {
      box-shadow: unset;
      outline: unset;
    }
  }
  &.button-orange {
    color: $white;
    background-color: $orange;
    &:hover,
    &:active,
    &:focus {
      box-shadow: unset;
      outline: none;
      border-color: $orange-hover;
      background-color: $orange-hover;
    }
  }
  &.button-orange-border {
    min-width: 40px;
    color: $orange;
    background-color: $white;
    border-color: $orange;
    &:hover,
    &:active,
    &:focus {
      box-shadow: unset;
      outline: none;
      border-color: $orange-hover;
      background-color: $white;
    }
    &.fit {
      padding: 0;
    }
  }
  &.button-password {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0;
    right: 10px;
    padding: 0;
    color: $text-grey;
    &:hover,
    &:focus,
    &:active {
      color: $text-black;
      outline: none;
      box-shadow: none;
    }
  }
  &.button-share {
    height: 40px;
    width: 40px;
    padding: 0;
    color: $text-grey;
    border-color: $white;
    background-color: $white;
    &:hover,
    &:focus,
    &:active {
      color: $cyan;
      outline: none;
      box-shadow: none;
      border-color: $cyan;
    }
  }
  &.button-donation {
    height: 55px;
    width: calc(33.33% - 30px);
    margin: 20px 15px 0;
    border-color: $orange;
    color: $text-black;
    background-color: $white;
    border-width: 2px;
    font-size: 1.25rem;
    font-weight: 600;
    &:hover,
    &:focus {
      background-color: $orange;
      color: $white;
    }
    span {
      font-size: 0.75rem;
      font-weight: 600;
      margin-bottom: 8px;
      margin-right: 2px;
    }
    .nominal-select-input {
      display: none;
    }
  }
  &.button-payment-type {
    color: $text-black;
    background-color: $white;
    border-color: $cyan;
    height: 55px;
    border-width: 2px;
    justify-content: space-between;
    @media (max-width: 500px) {
      height: 45px;
      border-radius: 15px;
      padding: 0 20px;
    }
    input {
      display: none;
    }
    p {
      font-weight: 600;
      color: $text-black;
      font-size: 1.25rem;
      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }
    img {
      height: 50px;
      @media (max-width: 500px) {
        height: 35px;
      }
    }
    i {
      color: $cyan;
    }
    &:hover,
    &:focus {
      p,
      i {
        color: $cyan-dark;
      }
    }
  }
  &.button-big {
    height: 55px;
    font-size: 1.125rem;
    @media (max-width: 500px) {
      height: 45px;
      border-radius: 15px;
      font-size: 1rem;
    }
  }
  &.button-copy {
    color: $orange;
    border: transparent;
    background-color: transparent;
    font-size: 1.25rem;
    font-weight: 500;
    span {
      color: $orange;
      @media (max-width: 500px) {
        display: none;
      }
    }
    i {
      color: $orange;
      margin-right: 5px;
    }
  }
  &.button-fbook {
    background-color: $color-fb;
    font-size: 1.125rem;
    font-weight: 500;
    color: $white;
    align-items: center;
    margin: 20px 0 10px;
    i {
      font-size: 2rem;
      margin-right: 10px;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: $cyan-dark;
      box-shadow: unset;
      outline: unset;
    }
  }
  &.button-share-wa {
    background-color: $color-wa;
    font-size: 1.125rem;
    font-weight: 500;
    color: $white;
    align-items: center;
    margin: 20px 0 15px;
    i {
      font-size: 2rem;
      margin-right: 10px;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: $whatsapp;
      box-shadow: unset;
      outline: unset;
    }
  }
  &.button-shadow {
    background-color: $white;
    box-shadow: 0 4px 21px $cyan-shadow;
    margin: 15px 0 0;
    font-size: 1.125rem;
    font-weight: 500;
    color: $text-black;
    img {
      height: 32px;
      margin-right: 10px;
      @media (max-width: 500px) {
        height: 24px;
        margin-right: 8px;
      }
    }
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 4px 21px $cyan-shadow-hover;
      outline: unset;
    }
    @media (max-width: 500px) {
      font-size: 1rem;
      padding: 0 15px;
    }
  }
  &.button-zakat {
    height: 55px;
    border-radius: 30px;
    font-size: 1.25rem;
  }
  &.no-rounded {
    border-radius: 10px !important;
  }
  @media (max-width: 500px) {
    height: 38px;
    min-width: 38px;
    font-size: 0.85rem;
  }
}
.slick-next,
.slick-prev {
  &:before {
    display: none;
  }
}

.category-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
  .button-category {
    width: 16.666%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: unset;
    text-align: center;
    color: $text-grey;
    padding: 10px 30px;
    @media (max-width: 500px) {
      width: 25%;
      padding: 8px 5px;
    }
    .category-rounded {
      height: 64px;
      width: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 34px;
      background-color: $white;
      border: 1px solid $white;
      margin-bottom: 8px;
      box-shadow: 0 1px 21px $cyan-shadow;
      @media (max-width: 500px) {
        width: 45px;
        height: 45px;
      }
      > img {
        height: 30px;
        width: 30px;
        @media (max-width: 500px) {
          height: 24px;
          width: 24px;
        }
      }
    }
    .category-text {
      font-size: 14px;
      text-align: center;
      width: 100%;
      height: 32px;
      font-weight: 500;
      @media (max-width: 500px) {
        font-size: 10px;
      }
    }
    &:hover,
    &:active,
    &:focus,
    &.active {
      color: $cyan;
      .category-rounded {
        border-color: $white;
        box-shadow: 0 1px 21px $cyan-shadow-hover;
      }
    }
  }
  &.choice-category {
    .button-category {
      @media (max-width: 500px) {
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }
      }
    }
  }
  &.modal-category {
    justify-content: flex-start;
  }
}
.button-download {
  height: 60px;
  display: flex;
  img {
    height: 60px;
  }
  @media (max-width: 500px) {
    height: 25px;
    img {
      height: 25px;
    }
  }
}
.button-profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  background-color: transparent;
  border-color: transparent;
  .button-image {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: 1px solid $cyan-shadow;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
    }
  }
  .button-text {
    text-align: start;
    margin-left: 10px;
    .text-name {
      font-weight: 600;
      color: $cyan;
      line-height: 1;
    }
    .text-email {
      color: $text-grey;
      font-weight: 300;
    }
  }
}

.dropdown-header-profile {
  .dropdown-menu {
    min-width: 300px;
    border-color: $white;
    box-shadow: 0 1px 10px $cyan-shadow;
    border-radius: 40px;
    overflow: hidden;
    padding: 25px 0;
  }
  .dropdown-divider {
    border-top: 3px solid $cyan-white;
  }
  .dropdown-item {
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
    align-items: center;
    .menu-icon {
      height: 24px;
      width: 24px;
      color: $text-grey;
      margin-right: 10px;
    }
    span {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1;
      color: $text-grey;
    }
    &:hover,
    &:focus {
      background-color: $cyan-white;
      span,
      .menu-icon {
        color: $cyan-dark !important;
      }
    }
  }
}
.tabs-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: $white;
  border: 1px solid $white;
  font-size: 1rem;
  font-weight: 500;
  color: $text-black;
  text-transform: uppercase;
  border-radius: 20px;
  &.active,
  &:hover,
  &:focus {
    outline: none;
    box-shadow: unset;
    background-color: $cyan;
    border-color: $cyan;
    color: $white;
  }
  @media (max-width: 500px) {
    height: 35px;
    font-size: 0.75rem;
  }
}

.tabs-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: $white;
  border: 1px solid $white;
  font-size: 1rem;
  font-weight: 500;
  color: $text-black;
  text-transform: uppercase;
  border-radius: 20px;
  &.active,
  &:hover,
  &:focus {
    outline: none;
    box-shadow: unset;
    background-color: $cyan;
    border-color: $cyan;
    color: $white;
  }
  @media (max-width: 500px) {
    height: 35px;
    font-size: 0.75rem;
  }
}

.tabs-button-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: $white;
  border: 1px solid $white;
  font-size: 1rem;
  font-weight: 500;
  color: $text-black;
  text-transform: uppercase;
  border-radius: 20px;
  width: 48%;
  &.active,
  &:hover,
  &:focus {
    outline: none;
    box-shadow: unset;
    background-color: $orange;
    border-color: $orange;
    color: $white;
  }
  @media (max-width: 500px) {
    height: 35px;
    font-size: 0.75rem;
  }
}

.payment-button-group {
  overflow: hidden;
  border-radius: 20px;
  border: 2px solid $cyan;
  margin: 15px 0 30px;
  .btn {
    &.button-payment-type {
      border-radius: 0;
      margin-bottom: 0;
      border: 0;
      border-bottom: 2px solid $cyan;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
