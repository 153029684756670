.home-banner-section {
  width: 100%;
  padding: 40px 0;
  @media (max-width: 500px) {
    padding: 20px 0;
  }
}
.home-banner {
  width: 100%;
  position: relative;
  .banner-item {
    width: calc(100% - 40px);
    padding: 0 20px;
    position: relative;
    @media (max-width: 500px) {
      width: calc(100% - 20px);
      padding: 0 10px;
    }
    img {
      width: 100%;
      border-radius: 20px;
    }
  }
  .slick-slide[aria-hidden="true"] {
    opacity: 0.5;
  }
  .slick-list {
    padding: 0 20% 0 !important;
    @media (max-width: 500px) {
      padding: 0 40px 0 !important;
    }
  }
  .slick-prev {
    left: 20%;
    @media (max-width: 500px) {
      display: none;
    }
    &:before {
      display: none;
    }
  }
  .slick-next {
    right: 20%;
    @media (max-width: 500px) {
      display: none;
    }
    &:before {
      display: none;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: -25px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    li {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      border-radius: 5px;
      @media (max-width: 500px) {
        width: 7px;
        height: 7px;
      }
      button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 10px;
        height: 10px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        border-radius: 5px;
        @media (max-width: 500px) {
          width: 7px;
          height: 7px;
        }
        &:hover,
        &:focus {
          outline: none;
          &:before {
            opacity: 1;
          }
        }
        &:before {
          font-family: "slick";
          font-size: 6px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          margin-left: 5px;
          content: "";
          opacity: 0.7;
          background-color: $text-light;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          border-radius: 5px;
          @media (max-width: 500px) {
            width: 7px;
            height: 7px;
          }
        }
      }
      &.slick-active {
        width: 60px;
        height: 10px;
        border-radius: 5px;
        @media (max-width: 500px) {
          width: 35px;
          height: 7px;
        }
        button {
          &:before {
            opacity: 1;
            background-color: $cyan;
            width: 60px;
            height: 10px;
            border-radius: 5px;
            @media (max-width: 500px) {
              width: 35px;
              height: 7px;
            }
          }
        }
      }
    }
  }
}
.home-menu-section {
  padding: 40px 0;
  width: 100%;
  @media (max-width: 500px) {
    padding: 20px 0;
  }
  .home-menu {
    background-color: $white;
    height: 80px;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    box-shadow: 0 2px 13px $cyan-shadow;
    @media (max-width: 500px) {
      box-shadow: none;
      padding: 0;
    }
    .menu-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media (max-width: 500px) {
        flex-direction: column;
        width: 20%;
        text-align: center;
        justify-content: flex-start;
        height: 90px;
        span {
          font-size: 12px !important;
        }
      }
      img {
        height: 35px;
        margin-right: 10px;
        @media (max-width: 500px) {
          width: 28px;
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      &:hover {
        span {
          color: $cyan !important;
        }
      }
    }
  }
  .banner-menu {
    margin-top: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: $white;
    padding: 40px;
    height: 150px;
    border-radius: 75px;
    box-shadow: 0 1px 18px $cyan-shadow;
    @media (max-width: 500px) {
      height: 80px;
      border-radius: 40px;
      padding: 20px;
      margin-top: 60px;
    }
    img {
      position: absolute;
      left: 50px;
      top: -30%;
      @media (max-width: 500px) {
        height: 80px;
        left: 25px;
      }
    }
    .banner-text {
      padding-left: 200px;
      width: 65%;
      @media (max-width: 500px) {
        padding-left: 100px;
        width: 80%;
        .text-subtitle {
          font-size: 14px !important;
        }
        .text-normal {
          font-size: 8px !important;
        }
      }
    }
    .banner-action {
      display: flex;
      align-items: center;
      @media (max-width: 500px) {
        flex-direction: column;
      }
      .btn {
        margin: 0 10px;
        @media (max-width: 500px) {
          height: 28px;
          width: 55px !important;
          font-size: 8px;
          padding: 0;
        }
      }
    }
  }
}
.home-program-section {
  width: 100%;
  background-color: $white;
}
.programs-upper {
  padding: 40px 0;
  width: 100%;
  position: relative;
  @media (max-width: 500px) {
    h1 {
      &.text-title {
        font-size: 14px !important;
      }
    }
    p {
      &.text-subtitle {
        font-size: 10px !important;
      }
    }
  }
  .upper-slider-wrapper {
    margin: 15px -15px;
    width: calc(100% + 30px);
    .slider-item {
      padding: 5px 15px 5px 15px;
      &:first-child {
        padding: 5px 15px 5px 0px;
      }
      &:last-child {
        padding: 5px 0px 5px 15px;
      }
    }
    .slick-next {
      right: 0px;
      top: -45px;
      @media (max-width: 500px) {
        height: 35px !important;
        width: 35px !important;
        right: 10px;
        top: -35px;
      }
    }
    .slick-prev {
      left: calc(100% - 90px);
      top: -45px;
      @media (max-width: 500px) {
        height: 35px !important;
        width: 35px !important;
        left: calc(100% - 87px);
        top: -35px;
      }
    }
    .slick-dots {
      position: absolute;
      bottom: -25px;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      li {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        border-radius: 5px;
        @media (max-width: 500px) {
          width: 7px;
          height: 7px;
        }
        button {
          font-size: 0;
          line-height: 0;
          display: block;
          width: 10px;
          height: 10px;
          padding: 5px;
          cursor: pointer;
          color: transparent;
          border: 0;
          outline: none;
          background: transparent;
          border-radius: 5px;
          @media (max-width: 500px) {
            width: 7px;
            height: 7px;
          }
          &:hover,
          &:focus {
            outline: none;
            &:before {
              opacity: 1;
            }
          }
          &:before {
            font-family: "slick";
            font-size: 6px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            margin-left: 5px;
            content: "";
            opacity: 0.7;
            background-color: $text-light;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            border-radius: 5px;
            @media (max-width: 500px) {
              width: 7px;
              height: 7px;
            }
          }
        }
        &.slick-active {
          width: 60px;
          height: 10px;
          border-radius: 5px;
          @media (max-width: 500px) {
            width: 35px;
            height: 7px;
          }
          button {
            &:before {
              opacity: 1;
              background-color: $cyan;
              width: 60px;
              height: 10px;
              border-radius: 5px;
              @media (max-width: 500px) {
                width: 35px;
                height: 7px;
              }
            }
          }
        }
      }
    }
    @media (max-width: 500px) {
      .slick-track {
        left: -50px !important;
      }
    }
  }
}
.program-row {
  width: 100%;
  padding: 40px 0;
  @media (max-width: 500px) {
    h1 {
      &.text-title {
        font-size: 14px !important;
      }
    }
    p {
      &.text-subtitle {
        font-size: 10px !important;
      }
    }
  }
  .program-row-slider {
    width: calc(100% + 20px);
    margin: 0px -10px;
    .slider-item {
      padding: 5px 10px;
    }
    .slick-next {
      right: 0px;
      top: -30px;
      @media (max-width: 500px) {
        height: 35px !important;
        width: 35px !important;
        right: 10px;
      }
    }
    .slick-prev {
      left: calc(100% - 90px);
      top: -30px;
      @media (max-width: 500px) {
        height: 35px !important;
        width: 35px !important;
        left: calc(100% - 87px);
      }
    }
    .slick-dots {
      position: absolute;
      bottom: -25px;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      li {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        border-radius: 5px;
        @media (max-width: 500px) {
          width: 7px;
          height: 7px;
        }
        button {
          font-size: 0;
          line-height: 0;
          display: block;
          width: 10px;
          height: 10px;
          padding: 5px;
          cursor: pointer;
          color: transparent;
          border: 0;
          outline: none;
          background: transparent;
          border-radius: 5px;
          @media (max-width: 500px) {
            width: 7px;
            height: 7px;
          }
          &:hover,
          &:focus {
            outline: none;
            &:before {
              opacity: 1;
            }
          }
          &:before {
            font-family: "slick";
            font-size: 6px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 10px;
            height: 10px;
            margin-left: 5px;
            content: "";
            opacity: 0.7;
            background-color: $text-light;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            border-radius: 5px;
            @media (max-width: 500px) {
              width: 7px;
              height: 7px;
            }
          }
        }
        &.slick-active {
          width: 60px;
          height: 10px;
          border-radius: 5px;
          @media (max-width: 500px) {
            width: 30px;
            height: 7px;
          }
          button {
            &:before {
              opacity: 1;
              background-color: $cyan;
              width: 60px;
              height: 10px;
              border-radius: 5px;
              @media (max-width: 500px) {
                width: 30px;
                height: 7px;
              }
            }
          }
        }
      }
    }
  }
}
.main-program {
  width: 100%;
  padding: 80px 0 40px;
  @media (max-width: 500px) {
    padding: 30px 0 20px;
    h1 {
      &.text-title {
        font-size: 14px !important;
      }
    }
    p {
      &.text-subtitle {
        font-size: 10px !important;
      }
    }
  }
  .main-program-list {
    .card-column {
      margin: 15px 0;
    }
  }
  @media (max-width: 500px) {
    .row {
      display: flex;
      overflow-x: auto;
      flex-wrap: nowrap;
      .col-12.col-md-4 {
        flex: 0 0 80%;
      }
    }
    .link-more {
      width: 100% !important;
    }
  }
}

.programs-bottom {
  padding: 40px 0;
  width: 100%;
  .link-more {
    display: none;
  }
  @media (max-width: 500px) {
    padding: 20px 0;
    h1 {
      &.text-title {
        font-size: 14px !important;
      }
    }
    p {
      &.text-subtitle {
        font-size: 10px !important;
      }
    }
    .title-wrapper {
      .w-85 {
        width: 100% !important;
      }
      .w-15 {
        display: none !important;
      }
    }
    .row {
      display: flex;
      overflow-x: auto;
      flex-wrap: nowrap;
      .col-12.col-md-4 {
        flex: 0 0 80%;
      }
      .col-12.col-md-6 {
        flex: 0 0 90%;
      }
    }
    .link-more {
      display: block;
      width: 100% !important;
    }
  }
}
