@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$poppins: "Poppins", sans-serif;

// color
$text-black: #575757;
$text-grey: #827e7e;
$text-light: #c4c4c4;
$text-solid: #1e2027;
$shadow: rgba(0, 0, 0, 0.25);

$cyan: #15bbda;
$cyan-dark: #3a99d8;
$cyan-white: rgba(92, 207, 229, 0.13);
$cyan-shadow: rgba(21, 187, 218, 0.25);
$cyan-shadow-hover: rgba(21, 187, 218, 0.5);
$cyan-hover: #129db5;
$whatsapp: #2aab27;
$white: #fff;
$orange: #f86f22;
$orange-hover: #fa5e07;
$cyan50: #99e1ef;
$cyan25: #c6eef5;
$cyan10: #d7edf1;
$orange75: #f99d6e;
$orange25: #fcbc2b3d;
$red: #be112a;
$color-fb: #2284f8;
$color-wa: #21bf1d;
$bg-dialog: rgba(0, 0, 0, 0.7);
$orange25: rgba(252, 118, 43, 0.24);
