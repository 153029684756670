.sub-footer {
  width: 100%;
  padding: 40px 0;
  background-color: $white;
  position: relative;
  @media (max-width: 500px) {
    background-color: #ebfbff;
    position: fixed;
    bottom: 69px;
    left: 0;
    z-index: 99;
  }
}
.app-banner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.banner-text {
  width: calc(100% - 300px);
  @media (max-width: 500px) {
    width: calc(100% - 100px);
  }
  h2 {
    margin-bottom: 5px;
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }
  h3 {
    @media (max-width: 500px) {
      font-size: 10px !important;
    }
  }
  .footer-download-wrapper {
    display: flex;
    padding: 20px 0 0;
    a {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
.banner-image {
  width: 300px;
  @media (max-width: 500px) {
    width: 100px;
  }
}

.footer-wrapper {
  padding: 40px 0 80px;
  width: 100%;
  background-color: $cyan-dark;
  @media (max-width: 500px) {
    display: none;
  }
  .footer-content {
    width: 100%;
  }
  .logo-footer {
    width: 250px;
    margin-bottom: 20px;
    img {
      width: 250px;
    }
  }
  .social-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 0;
    margin-bottom: 20px;
    .social-link {
      height: 40px;
      width: 40px;
      margin-left: 15px;
      display: flex;
      font-size: 40px;
      color: $white;
      background-color: transparent;
      align-items: center;
      justify-content: center;
    }
  }
  .footer-menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .footer-menu {
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      &:last-child {
        margin-right: 0;
        width: 35%;
      }
      .footer-menu-title {
        font-size: 1.125rem;
        font-weight: 600;
        color: $white;
        margin-bottom: 1rem;
      }
      .footer-menu-link {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
        color: $white;
      }
    }
  }
  .footer-text {
    font-size: 1rem;
    font-weight: 400;
    color: $white;
  }
}

.footer-mobile-menu {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  background-color: $white;
  z-index: 1050;
  height: 70px;
  box-shadow: 0 1px 10px $cyan-shadow;
  @media (max-width: 500px) {
    display: flex;
    align-items: center;
  }
  .mobile-menu {
    padding: 10x;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menu-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 20%;
      .menu-icon {
        width: 28px;
        height: 28px;
        margin-bottom: 5px;
        color: $text-black;
      }
      span {
        font-size: 10px;
        font-weight: 500;
        display: block;
        height: 10px;
        color: $text-black;
      }
      &.active {
        .menu-icon {
          color: $cyan-dark;
        }
      }
    }
  }
}
.to-top-wrapper {
  width: 50px;
  position: fixed;
  bottom: 40px;
  right: 70px;
  z-index: 99;
  @media (max-width: 500px) {
    bottom: 290px;
    right: 0px;
  }
}
.contact-float-wrapper {
  width: 80px;
  position: fixed;
  bottom: 40px;
  right: 140px;
  z-index: 99;
  @media (max-width: 500px) {
    bottom: 85px;
    right: 25px;
  }
}
.notification-wrapper {
  width: fit-content;
  padding: 10px 20px;
  border-radius: 40px;
  position: fixed;
  bottom: 40px;
  left: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $white;
  box-shadow: 0 1px 4px $text-light;
  z-index: 99;
  @media (max-width: 500px) {
    padding: 8px 10px;
    bottom: 85px;
    left: 10px;
  }
  .notification-image {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    border: 1px solid $cyan-shadow;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 500px) {
      height: 28px;
      width: 28px;
      border-radius: 14px;
    }
    img {
      max-width: 100%;
    }
  }
  .notification-text {
    margin-left: 10px;
    @media (max-width: 500px) {
      margin-left: 5px;
    }
    h5 {
      font-size: 14px;
      color: $text-black;
      font-weight: 600;
      span {
        font-size: 12px;
      }
      @media (max-width: 500px) {
        font-size: 12px;
        span {
          font-size: 10px;
        }
      }
    }
    h6 {
      font-size: 12px;
      color: $text-grey;
      font-weight: 500;
      @media (max-width: 500px) {
        font-size: 10px;
      }
    }
    p {
      font-size: 10px;
      color: $text-light;
      font-weight: 400;
      @media (max-width: 500px) {
        font-size: 8px;
      }
    }
  }
}
.button-donation-wrapper {
  width: 100%;
  display: none;
  position: fixed;
  bottom: 70px;
  left: 0;
  z-index: 1;
  background-color: rgba($color: $white, $alpha: 0.6);
  @media (max-width: 500px) {
    display: block;
  }
}
.button-donation-float {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn {
    &.button-orange {
      width: calc(100% - 60px);
    }
  }
}
