.header-white {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 90px;
  box-shadow: 0 4px 11px $cyan-shadow;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1050;
  background-color: $white;
  @media (max-width: 500px) {
    height: 75px;
  }
}
.header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &.header-auth {
    justify-content: center;
  }
  &.header-title {
    justify-content: flex-start;
  }
}
.header-title {
  width: 100%;
  .title-text {
    font-size: 1.25rem;
    font-weight: 600;
    width: 100%;
    i {
      margin-right: 10px;
      color: $cyan;
    }
  }
}
.header-logo {
  width: fit-content;
  margin-right: 20px;
  @media (max-width: 500px) {
    margin-right: 15px;
  }
  > a {
    width: 100%;
    img {
      width: 180px;
      &.logo-mobile {
        display: none;
      }
      &.logo {
        display: block;
      }
      &.logo-auth {
        width: 180px;
      }
      @media (max-width: 500px) {
        width: 38px;
        &.logo-mobile {
          display: block;
        }
        &.logo {
          display: none;
        }
        &.logo-auth {
          width: 180px;
        }
      }
    }
  }
}
.header-search {
  width: calc(100% - 500px);
  position: relative;
  @media (max-width: 500px) {
    width: calc(100% - 120px);
  }
  .btn {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0px;
    right: 5px;
    font-size: 1rem;
    color: $cyan;
    @media (max-width: 500px) {
      font-size: 14px;
      width: 30px;
      right: 0;
    }
  }
}
.form-header-search {
  height: 40px;
  border-radius: 20px;
  padding-right: 60px;
  padding-left: 20px;
  background-color: $cyan-white;
  color: $text-light;
  border-color: $cyan-white;
  font-size: 16px;
  @media (max-width: 500px) {
    font-size: 12px;
  }
  &::placeholder {
    color: $text-light;
  }
  &:focus,
  &:visited {
    background-color: $white;
    color: $text-black;
    border-color: $cyan-white;
    box-shadow: unset;
    outline: none;
    &::placeholder {
      color: $text-light;
    }
    + .btn {
      color: $text-light;
    }
  }
}
.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    margin-left: 20px;
  }
  @media (max-width: 500px) {
    display: none;
  }
}
.header-menu-mobile {
  display: none;
  margin-left: 15px;
  @media (max-width: 500px) {
    display: block;
    max-width: 40px;
  }
}
