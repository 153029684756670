.donation-form-section {
  width: 100%;
  background-color: $white;
  padding: 40px 0;
  &.nominal-page {
    padding-bottom: 100px;
  }
}
.input-donation {
  width: 100%;
  position: relative;
}
.donation-form-content {
  width: 100%;
  position: relative;
  padding: 20px 0 0;
  &.focus {
    .background-hover {
      display: block;
    }
    h2 {
      color: $white !important;
    }
    .donation-group-text {
      display: flex;
    }
    .input-donation {
      z-index: 1053;
    }
  }
  .background-hover {
    display: none;
    width: 100%;
    height: 100vh;
    z-index: 1051;
    background-color: $bg-dialog;
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
  }
}
.donation-group-text {
  width: 100%;
  display: none;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 5px 20px;
  margin-top: 20px;
  background-color: $white;
  border-radius: 20px;
  border-radius: 2px solid $text-grey;
  position: absolute;
  top: 90px;
  left: 0;
}
.donate-platform-box {
  width: 100%;
  padding: 20px;
  background-color: $cyan25;
  border-radius: 20px;
  margin-bottom: 20px;
  .donate-platform-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  h3 {
    font-size: 1.25rem;
  }
  .donate-platform-body {
    p {
      font-size: 1rem;
      margin-bottom: 20px;
      color: $text-grey;
    }
    input {
      background-color: $cyan25;
    }
  }
}
.footer-donation {
  position: fixed;
  width: 100%;
  padding: 10px 20px;
  bottom: 0;
  left: 0;
  background-color: $white;
  box-shadow: 0 -6px 10px $cyan-shadow;
  z-index: 1050;
}

.box-donation-intruction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 5px solid $cyan;
  height: 75px;
  width: 100%;
  border-radius: 40px;
  padding: 0 30px;
  margin: 15px 0 25px;
  img {
    height: 60px;
  }
  p {
    font-size: 1.25rem;
    font-weight: 600;
    span {
      color: $orange;
      font-weight: 700;
    }
  }
  &.no-rounded {
    height: unset;
    padding: 20px 30px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 40px 0 30px;
    p {
      font-weight: 400;
      display: flex;
      text-align: start;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    span {
      color: $text-black;
      font-weight: 400;
      &.label-text {
        width: 110px;
        display: block;
      }
      &.label-dot {
        width: 25px;
        display: block;
      }
    }
  }
}
.box-donation-alert {
  background-color: $orange;
  padding: 5px 25px;
  width: 100%;
  text-align: start;
  border-radius: 40px;
  p {
    font-size: 1rem;
    font-weight: 500;
    color: $white;
    span {
      color: $text-black;
      font-size: 1.125rem;
      font-weight: 600;
    }
  }
}
.banner-regular-donation-section {
  width: 100%;
  padding: 40px 0 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  @media (max-width: 500px) {
    padding: 25px 0;
  }
}
.banner-regular-donation {
  padding: 80px 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
    padding: 0 20px;
    text-align: center;
    &.banner-zakat {
      flex-direction: column-reverse;
      position: relative;
      padding-bottom: 30px;
    }
    p {
      font-size: 0.85rem !important;
    }
  }

  img {
    height: 110px;
    margin-right: 20px;
    @media (max-width: 500px) {
      margin-right: 0;
      margin-bottom: 20px;
      height: 80px;
    }
    &.img-zakat {
      height: 150px;
      margin-right: 0;
      margin-left: 20px;
      @media (max-width: 500px) {
        margin: 0 0 20px;
        height: 80px;
      }
    }
  }
  h1 {
    margin-bottom: 10px;
    @media (max-width: 500px) {
      font-size: 1rem !important;
    }
  }
}
.title-regular-donation {
  text-align: center;
  @media (max-width: 500px) {
    display: none;
  }
  h1 {
    font-size: 1.25rem;
    font-weight: 600;
    color: $white;
  }
}
.regular-donation-form {
  width: 80%;
  margin: 40px auto;
  @media (max-width: 500px) {
    width: 100%;
    padding: 0 20px;
  }
}
