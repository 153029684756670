.text-title {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}
.text-title-two {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}
.text-subtitle {
  font-size: 1.125rem !important;
  font-weight: 500 !important;
}
.text-semi-normal {
  font-size: 1rem !important;
  font-weight: 500 !important;
}
.text-normal {
  font-size: 0.85rem !important;
  font-weight: 400 !important;
}
.text-small {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}
.text-extra-small {
  font-size: 0.625rem !important;
  font-weight: 400 !important;
}
.text-link {
  font-weight: 500 !important;
  font-size: 1rem;
  color: $cyan !important;
  &:hover {
    color: $cyan-hover !important;
  }
}
.text-normal-weight {
  font-weight: 400 !important;
}
.text-bold {
  font-weight: 600 !important;
}
.text-thin-weight {
  font-weight: 300 !important;
}
.text-italic {
  font-style: italic !important;
}

.article-content {
  p {
    font-size: 1rem !important;
    margin-bottom: 15px;
    color: $text-grey;
    @media (max-width: 500px) {
      font-size: 0.75rem !important;
      margin-bottom: 10px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $text-black;
    margin-bottom: 15px;
    @media (max-width: 500px) {
      margin-bottom: 10px;
    }
  }
  img {
    max-height: 300px;
    max-width: 100%;
    border-radius: 25px;
    margin-bottom: 15px;
    @media (max-width: 500px) {
      max-height: 200px;
      margin-bottom: 10px;
    }
  }
}
