.top-banner-auth-section {
  width: 100%;
  padding: 40px 0;
  background-color: $cyan-white;
  @media (max-width: 500px) {
    background-color: $white;
    padding: 20px 0;
  }
}
.top-banner-auth {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .top-banner-auth-content {
    width: 60%;
    @media (max-width: 500px) {
      width: calc(100% - 110px);
      .text-title-two {
        font-size: 0.85rem !important;
      }
      .text-semi-normal {
        font-size: 0.75rem !important;
      }
      .text-normal {
        display: none;
      }
    }
  }
  .top-banner-auth-image {
    width: 35%;
    @media (max-width: 500px) {
      width: 100px;
    }
    img {
      width: 100%;
    }
  }
}
.auth-form-section {
  width: 100%;
  padding: 40px 0;
  background-color: $white;
  @media (max-width: 500px) {
    padding: 20px 0;
  }
}
.tabs-auth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 40px;
  max-width: 700px;
  margin: 0 auto;
  @media (max-width: 500px) {
    padding: 0 0 20px;
  }
}
.form-auth {
  display: none;
  max-width: 700px;
  margin: 0 auto;
  &.show {
    display: block;
  }
  .auth-text {
    margin: 10px 0;
    color: $text-grey;
    @media (max-width: 500px) {
      font-size: 0.75rem;
    }
  }
  .btn {
    &.button-cyan {
      margin: 30px 0;
    }
  }
  @media (max-width: 500px) {
    .text-link {
      font-size: 0.75rem !important;
    }
  }
}

.bottom-banner-auth-section {
  width: 100%;
  padding: 40px 0;
  background-color: $white;
  position: relative;
  @media (max-width: 500px) {
    background-color: #ebfbff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    > .container {
      display: flex;
    }
  }
}
.bottom-banner-auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  @media (max-width: 500px) {
    width: calc(100% - 90px);
    align-items: flex-start;
  }
  .bottom-banner-auth-content {
    text-align: center;
    @media (max-width: 500px) {
      text-align: start;
    }
    .text-title-two {
      @media (max-width: 500px) {
        font-size: 14px !important;
        margin-top: 20px;
      }
    }
    .text-semi-normal {
      @media (max-width: 500px) {
        font-size: 10px !important;
      }
    }
  }
  .bottom-banner-auth-image {
    display: flex;
    justify-content: center;
    align-items: center;
    > .button-download {
      margin: 0 10px;
      @media (max-width: 500px) {
        height: 25px;
        margin: 0 10px 0 0;
      }
      img {
        height: 60px;
        @media (max-width: 500px) {
          height: 25px;
        }
      }
    }
  }
}
.bottom-banner-auth-icon {
  display: none;
  @media (max-width: 500px) {
    display: block;
    width: 90px;
  }
}
