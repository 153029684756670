textarea {
  &.form-control {
    padding: 5px 20px;
    resize: none;
    height: 90px;
    &.no-rounded {
      height: 150px;
    }
  }
}

.form-control {
  height: 40px;
  border-radius: 20px;
  padding: 0 20px;
  border: 2px solid $cyan;
  font-size: 1rem;
  font-weight: 500;
  &:hover,
  &:focus,
  &:visited {
    border-color: $cyan-dark;
    box-shadow: unset;
    outline: none;
  }
  &::placeholder {
    font-weight: 300;
    color: $text-light;
  }
  @media (max-width: 500px) {
    height: 38px;
    font-size: 0.85rem;
  }
  &.no-rounded {
    height: 55px;
    font-size: 1.125rem;
    font-weight: 600;
    @media (max-width: 500px) {
      height: 45px;
      border-radius: 15px;
      font-size: 1rem;
    }
    &::placeholder {
      font-weight: 400;
      color: $text-light;
    }
  }
}
select {
  &.form-filter {
    background: transparent;
    background-image: url("../img/main/svg/icon_caret_select.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 5px);
    background-position-y: 5px;
    appearance: none;
    border-color: $cyan-dark;
  }
  &.form-control {
    background: transparent;
    background-image: url("../img/main/svg/icon_caret_select.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 5px);
    background-position-y: 5px;
    appearance: none;
    height: 40px;
    border-radius: 20px;
    padding: 0 20px;
    border: 2px solid $cyan;
    font-size: 1rem;
    font-weight: 500;
    &:hover,
    &:focus,
    &:visited {
      border-color: $cyan-dark;
      box-shadow: unset;
      outline: none;
    }
    &::placeholder {
      font-weight: 300;
      color: $text-light;
    }
    @media (max-width: 500px) {
      height: 38px;
      font-size: 0.85rem;
    }
  }
}
.form-group {
  margin-bottom: 20px;
  position: relative;
  @media (max-width: 500px) {
    margin-bottom: 15px;
  }
  label {
    color: $text-grey;
    font-size: 0.85rem;
    @media (max-width: 500px) {
      font-size: 0.75rem;
    }
  }
  .nominal-group {
    position: relative;
    z-index: 9;
    .form-control {
      text-align: end;
      padding-left: 40px;
      font-weight: 600;
      &::placeholder {
        color: $text-black;
        font-weight: 600;
      }
    }
    .currency-text {
      font-size: 1.25rem;
      font-weight: 600;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }
  }
  .select-input {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    select {
      height: 55px;
      width: 120px;
      border: 2px solid $cyan;
      padding: 0 20px;
      font-size: 1.125rem;
      font-weight: 600;
      color: $white;
      background-color: $cyan;
      border-radius: 20px 0 0 20px;
      &:hover,
      &:focus,
      &:focus-visible,
      &:target,
      &:visited {
        box-shadow: unset;
        outline: none;
      }
      @media (max-width: 500px) {
        height: 45px;
        border-radius: 15px 0 0 15px;
        font-size: 1rem;
        width: 110px;
        padding-left: 10px;
      }
    }
    input {
      width: calc(100% - 120px);
      height: 55px;
      border: 2px solid $cyan;
      padding: 0 20px;
      font-size: 1.125rem;
      font-weight: 600;
      border-radius: 0 20px 20px 0;
      color: $text-black;
      @media (max-width: 500px) {
        height: 45px;
        border-radius: 0 15px 15px 0;
        font-size: 1rem;
        width: calc(100% - 110px);
      }
      &::placeholder {
        color: $text-light;
        font-weight: 400;
      }
      &:hover,
      &:focus,
      &:focus-visible,
      &:target,
      &:visited {
        box-shadow: unset;
        outline: none;
      }
    }
  }
}
.form-password {
  position: relative;
  width: 100%;
  input {
    padding-right: 60px;
  }
}
.form-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  .image-preview {
    height: 90px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 10px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  label {
    color: $cyan;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  input {
    display: none;
  }
}

.custom-switch {
  display: flex;
  align-items: center;
  .custom-control-label {
    color: $text-black;
    font-size: 0.85rem;
    font-weight: 500;
    padding-left: 10px;
    &::before {
      background-color: $cyan50;
      border-color: $cyan50;
      width: 2.5rem;
      height: 18px;
      border-radius: 10px;
      top: 1px;
      &:hover,
      &:focus,
      &:focus-visible,
      &:target,
      &:visited {
        box-shadow: unset;
        outline: none;
        border-color: transparent;
      }
    }
    &::after {
      height: 14px;
      width: 14px;
      background-color: $white;
      top: 3px;
    }
    &:hover,
    &:focus,
    &:focus-visible,
    &:target,
    &:visited {
      box-shadow: unset;
      outline: none;
      border-color: transparent;
    }
  }
  .custom-control-input {
    border-color: transparent;
    &:focus {
      ~ .custom-control-label {
        &::before {
          box-shadow: unset;
          outline: none;
          border-color: transparent;
        }
      }
    }
    &:checked {
      ~ .custom-control-label {
        &::before {
          background-color: $cyan50;
          border-color: $cyan50;
          &:hover,
          &:focus,
          &:focus-visible,
          &:target,
          &:visited {
            box-shadow: unset;
            outline: none;
            border-color: transparent;
          }
        }
        &::after {
          transform: translateX(1.3rem);
          background-color: $cyan-dark;
          &:hover,
          &:focus,
          &:focus-visible,
          &:target,
          &:visited {
            box-shadow: unset;
            outline: none;
            border-color: transparent;
          }
        }
        &:hover,
        &:focus,
        &:focus-visible,
        &:target,
        &:visited {
          box-shadow: unset;
          outline: none;
          border-color: transparent;
        }
      }
    }
    &:hover,
    &:focus,
    &:focus-visible,
    &:target,
    &:visited {
      box-shadow: unset;
      outline: none;
      border-color: transparent;
    }
  }
  &:hover,
  &:focus,
  &:focus-visible,
  &:target,
  &:visited {
    box-shadow: unset;
    outline: none;
    border-color: transparent;
  }
}
.form-switch-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  .custom-control {
    min-height: unset;
  }
  .custom-control-input {
    height: unset;
    display: none;
  }
  .label-row {
    margin-bottom: unset;
    @media (max-width: 500px) {
      font-size: 0.85rem;
    }
  }
  .custom-switch {
    display: flex;
    align-items: center;
    .custom-control-label {
      &::before {
        top: -10px;
      }
      &::after {
        top: -8px;
      }
    }
  }
}
