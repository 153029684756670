.button-zakat-wrap {
  width: 100%;
  padding: 0 40px;
  @media (max-width: 500px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .btn {
      height: 50px !important;
      font-size: 0.85rem;
    }
  }
}
.zakat-group-wrap {
  width: 100%;
  padding: 25px 40px;
  @media (max-width: 500px) {
    padding: 50px 10px;
  }
}
.zakat-box {
  width: 100%;
  padding: 20px;
  margin: 15px 0;
  border-radius: 30px;
  background-color: $cyan25;
  .box-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    img {
      height: 70px;
      margin-right: 15px;
    }
    p {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .form-zakat {
    width: 100%;
    .form-group {
      .nominal-group {
        position: relative;
        z-index: 9;
        .form-control {
          text-align: end;
          padding-left: 40px;
          font-weight: 600;
          font-size: 1rem;
          border-color: $text-light;
          &::placeholder {
            color: $text-black;
            font-weight: 600;
          }
          &:hover,
          &:focus,
          &:focus-visible,
          &:target,
          &:visited {
            border-color: $text-grey;
          }
        }
        .currency-text {
          font-size: 1rem;
          font-weight: 600;
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
        }
      }
    }
  }
  &.dashboard {
    .box-header {
      img {
        height: 60px;
      }
      p {
        font-size: 0.85rem;
      }
    }
  }
}
