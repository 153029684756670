.banner-infaq-section {
  width: 100%;
  height: calc(100vh - 90px);
  padding: 40px 0 0;
  @media (max-width: 500px) {
    padding: 20px 0;
    height: calc(75vh - 90px);
  }
}
.desktop-banner-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media (max-width: 500px) {
    display: none;
  }
  .left-content {
    width: 50%;
    text-align: left;
    h1 {
      font-size: 1.25rem;
      font-weight: 600;
      color: $white;
      margin-bottom: 10px;
    }
    p {
      font-size: 0.85rem;
      font-weight: 400;
      color: $white;
    }
  }
  .right-content {
    width: 50%;
    text-align: right;
    h1 {
      font-size: 1.25rem;
      font-weight: 600;
      color: $white;
    }
    p {
      font-size: 0.85rem;
      font-weight: 400;
      color: $white;
      margin-bottom: 10px;
    }
  }
  .full-content {
    width: 100%;
    margin: 30px 0;
    p {
      font-size: 0.85rem;
      font-weight: 400;
      color: $white;
      margin-bottom: 10px;
    }
  }
}
.mobile-banner-content {
  display: none;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    text-align: center;
    &.banner-zakat {
      flex-direction: column-reverse;
      position: relative;
      padding-bottom: 30px;
    }
    p {
      font-size: 0.85rem !important;
    }
    img {
      margin-right: 0;
      margin-bottom: 20px;
      height: 80px;
      &.img-zakat {
        margin: 0 0 20px;
        height: 80px;
      }
    }
    h1 {
      font-size: 1rem !important;
      margin-bottom: 5px;
    }
  }
}
.infaq-form-float {
  position: relative;
  top: -500px;
  left: 0;
  width: 100%;
  z-index: 1;
  margin-bottom: -450px;
  @media (max-width: 500px) {
    top: -330px;
  }
}
.infaq-card {
  width: 100%;
  padding: 20px 30px;
  border-radius: 20px;
  background-color: $white;
  box-shadow: 0 3px 14px $cyan-shadow;
  @media (max-width: 500px) {
    padding: 15px;
    margin-bottom: 130px;
  }
  .infaq-form {
    width: 100%;
    margin-bottom: 40px;
    @media (max-width: 500px) {
      .text-title-two {
        font-size: 1rem !important;
      }
      .text-subtitle {
        font-size: 0.85rem !important;
      }
    }
  }
  .infaq-table-wrapp {
    width: 100%;
  }
  .infaq-table-more {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $text-light;
    padding-top: 20px;
    .btn {
      width: fit-content;
    }
  }
}
.mobile-detail-infaq {
  display: none;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0 40px;
    h2 {
      font-size: 0.85rem;
      font-weight: 600;
      color: $text-grey;
      margin-bottom: 20px;
    }
    .mobile-infaq-nominal {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      background-color: $orange25;
      padding: 0px 10px;
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 600;
      color: $text-black;
      margin-bottom: 10px;
    }
    p {
      font-size: 0.75rem;
    }
  }
}
